import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AppContext from '../contexts/AppContext';

import StepWizard from 'react-step-wizard';
import styled from 'styled-components';

import FirstFeedbackStep from '../components/FirstFeedbackStep';
import SecondFeedbackStep from '../components/SecondFeedbackStep';
import { intercomShutdown } from '../utils/intercom';
import i18n from '../i18n';
import { showError } from '../services/toastService';

import { logAnalytics } from '../services/analyticsLogger';

import {
    postChatFeedbackV2,
} from '../services/feedbackService';

const Wrapper = styled.div`
  flex: 1;  
  justify-content: center;
  align-items: center;
`;

const StyledStepWizard = styled(StepWizard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: auto;
  background-color: aliceblue;
  border-radius: 16px;
  box-shadow: 0px 8px 16px 8px rgba(21, 21, 41, 0.25);
  height: calc(100vh - 60px);
  margin: 30px auto;
  width: 100%;
  max-width: 560px;
  @media (max-width: 768px) {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    height: 100vh;
    width: 100%;
  }
  > div {
    width: 100%;
    max-width: 560px;
  }
`;

const improvementsMap = {
  bad_connection: 'bad_connection', 
  connection_dropped: 'connection_dropped', 
  app_was_slow: 'app_was_slow', 
  quality_of_interpretation: 'quality_of_interpretation', 
  interpreter_was_in_poor_location: 'interpreter_was_in_poor_location', 
  interpreter_finished: 'interpreter_finished'
}; 

const initialFeedbackValues = {
  stars: 0,
  improvements: [],
  freeText: '',
  contactWanted: false,
};

const FeedbackV2 = () => {
  const { user } = useContext(AppContext);

  const location = useLocation();
  const history = useHistory();
  const [ chatroomId ] = useState(location?.state?.chatroomId || location?.state?.roomId || null);
  const [ prebookingId ] = useState(location?.state?.prebookingId || null);
  const [ prebooking ] = useState(location?.state?.prebooking || null);
  const [ backUrl ] = useState(location?.state?.from || '/');

  const [header, setHeader] = useState('');
  const [currentPath] = useState(['howDidItGo', 'howCanWeImprove']);
  const [pageIndex, setPageIndex] = useState(0);
  const [stepWizardInstance, setStepWizardInstance] = useState(null);
  const [improvementsKeys] = useState(Object.values(improvementsMap));
  const [feedback, setFeedback] = useState(initialFeedbackValues);
  const [indicator, setIndicator] = useState(false);
  const [ isMobileWidth, setIsMobileWidth ] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobileWidth(true);
    }
    intercomShutdown();
  }, []);


  useEffect(() => {
    setHeader(pageIndex <= currentPath.length - 1 ?
      `${pageIndex + 1} / ${currentPath.length}` : null);
  }, [pageIndex, currentPath]);

  const nextStep = () => {
    if (stepWizardInstance) {
      stepWizardInstance.nextStep();
      logAnalytics('Move to the next feedback step');
    }
  };

  const previousStep = () => {
    if (stepWizardInstance) {
      stepWizardInstance.previousStep();
      logAnalytics('Back to the previous feedback step');
    }
  };

  const backPagePath = useMemo(() => {
    if (location?.state?.roomId) {
      return '/booking';
    } else {
      return backUrl;
    }
  }, [location?.state?.roomId, backUrl]);

  const handleRatingChange = (field, value) => {
    setFeedback({
      ...feedback,
      [field]: value,
    });
  };

  const handleImprovementSelection = (improvement) => {
    setFeedback((prev) => {
      return {
        ...prev, 
        improvements: prev.improvements.includes(improvement) 
          ? prev.improvements.filter(item => item !== improvement) 
          : [...prev.improvements, improvement]};
    });
  };

  const handleOpenFeedbackChange = (value) => {
    setFeedback({
      ...feedback,
      freeText: value,
    });
  };

  const handleContactChange = () => {
    setFeedback({
      ...feedback,
      contactWanted: !feedback.contactWanted,
    });
  };

  const getStarsValues = (skipped) => {
    const isTechnical = feedback.improvements.find((item) => item === improvementsMap.bad_connection || item === improvementsMap.connection_dropped || item === improvementsMap.app_was_slow);
    const isUser = feedback.improvements.find((item) => item === improvementsMap.quality_of_interpretation || item === improvementsMap.interpreter_was_in_poor_location || item === improvementsMap.interpreter_finished);
    if (skipped) {
      return {
        starsGeneral: 0, 
        starsTechnical: 0, 
        starsUser: 0,
      };
    }
    return {
      starsGeneral: feedback.stars, 
      starsTechnical: isTechnical ? feedback.stars : 0, 
      starsUser: isUser ? feedback.stars : 0,
    };
  };

  const handleSubmit = async(skipped) => {
    const stars = getStarsValues(skipped);
    const feedbackValues = skipped ? initialFeedbackValues : feedback;
    
    const preparedFeedback = { 
      ...feedbackValues, 
      ...stars,
      userId: user.id,
      chatroomId,
      prebookingId,
      invoiceRef: prebooking?.invoiceRef || '',
      invoiceUserGroupId: prebooking?.invoiceUserGroup?.id
    }; // one score value for all three categories

    setIndicator(true);
    try {
      if (chatroomId || prebookingId) {
        await postChatFeedbackV2(preparedFeedback);
        logAnalytics('Feedback submitted');
        history.replace(backPagePath);
      }

    } catch (err) {
      console.error(err);
      logAnalytics('Feedback submission failed');
      showError(i18n('failedToSendFeedback'));
    }
    setIndicator(false);
  };

  return (
    <Wrapper>
      <StyledStepWizard
        isHashEnabledinstance={setStepWizardInstance}
        initialStep={1}
        onStepChange={(stepData) => {
          setPageIndex(stepData.activeStep - 1);
        }}
        isLazyMount={true}
      >
        <FirstFeedbackStep
          header={header}
          nextStep={nextStep}
          previousStep={previousStep}
          skipStep={handleSubmit}
          stars={feedback.stars}
          improvements={improvementsKeys}
          selectedImprovements={feedback.improvements}
          handleImprovementSelection={handleImprovementSelection}
          handleRatingChange={handleRatingChange}
          indicator={indicator}
        />
        <SecondFeedbackStep
          header={header}
          nextStep={nextStep}
          previousStep={previousStep}
          skipStep={handleSubmit}
          improvements={improvementsKeys}
          selectedImprovements={feedback.improvements}
          handleImprovementSelection={handleImprovementSelection}
          contactWanted={feedback.contactWanted}
          handleContactChange={handleContactChange}
          openFeedback={feedback.freeText}
          handleOpenFeedbackChange={handleOpenFeedbackChange}
          handleSubmit={handleSubmit}
          indicator={indicator}
          isMobileWidth={isMobileWidth}
        />
      </StyledStepWizard>
    </Wrapper>
  );
};

export default FeedbackV2;
